const defintionLineupTab: iModalTabDefinition[] = [
  { slot: 'proj', label: 'Projections', icon: 'i-mdi-human-male-height', description: 'Player Projections' },
  { slot: 'match', label: 'Match', icon: 'i-mdi-kabaddi', description: 'Match Players' },
]

export const lineupRefreshTimes: Record<keyof iLineupRefreshDates, { title: string, refreshTime: number }> = {
  state: { title: 'Current State', refreshTime: 60 * 60 * 2 * 1000 },
  games: { title: 'Games', refreshTime: 60 * 60 * 1 * 1000 },
  leagues: { title: 'Leagues', refreshTime: 60 * 60 * 2 * 1000 },
  leagueRosters: { title: 'League Rosters', refreshTime: 60 * 60 * 2 * 1000 },
  leagueUsers: { title: 'League Users', refreshTime: 60 * 60 * 2 * 1000 },
  leagueMatchups: { title: 'League Matchups', refreshTime: 60 * 60 * 2 * 1000 },
  userMatches: { title: 'User Matches', refreshTime: 60 * 60 * 2 * 1000 },
  projections: { title: 'Projections', refreshTime: 60 * 60 * 1 * 1000 },
  injuries: { title: 'Injuries', refreshTime: 60 * 5 * 1000 },
  startData: { title: 'Start Data', refreshTime: 60 * 5 * 1000 },
  waivers: { title: 'Waiver Trends', refreshTime: 60 * 5 * 1000 },
}

export const useLineupStore = defineStore({
  id: 'LineupStore',
  state: (): iLineupStoreState => ({
    username: '',
    state: {},
    userId: undefined,
    games: [],
    starters: [],
    playerChanges: [],
    byeChanges: [],
    injuryChanges: [],
    flexChanges: [],
    trendingWaivers: [],
    ownershipWaivers: [],
    leagues: {},
    leagueRosters: {},
    leagueUsers: {},
    leagueMatchups: {},
    leaguePlayers: {},
    userMatches: {},
    benchStatus: {},
    injuries: new Map(),
    startData: {},
    projections: new Map(),
    showBenched: false,
    showModal: false,
    showBestBall: false,
    modalTab: 0,
    leagueModal: '',
    isLoading: false,
    refreshDates: {
      state: 0,
      injuries: 0,
      startData: 0,
      games: 0,
      leagues: 0,
      leagueRosters: 0,
      leagueUsers: 0,
      userMatches: 0,
      leagueMatchups: 0,
      waivers: 0,
      projections: 0,
    },
  }),
  persist: {
    pick: [
      'username',
      'state',
      'userId',
      'games',
      'starters',
      'playerChanges',
      'byeChanges',
      'injuryChanges',
      'flexChanges',
      'trendingWaivers',
      'ownershipWaivers',
      'leagues',
      'leagueRosters',
      'leagueUsers',
      'leagueMatchups',
      'leaguePlayers',
      'userMatches',
      'benchStatus',
      'injuries',
      'startData',
      'projections',
      'refreshDates',
    ],
  },
  getters: {
    hasData(state): boolean {
      return (state?.userId?.user_id?.length || 0) > 0
    },
    getModalTabConfig(): iModalTabDefinition[] {
      return defintionLineupTab
    },
    getLeagueModalInfo(state): iSleeperLeague {
      return state.leagues[state.leagueModal]
    },
    getLeagueModalMatches(state): iLineupMatches {
      return state.userMatches[state.leagueModal]
    },
    isAnyBenchOpen(state): boolean {
      return Object.values(state.benchStatus).some(value => value)
    },
    getPlayerUserIds(state): Record<string, number> {
      const playerUserIds: Record<string, number> = {}
      for (const [leagueId, rosters] of Object.entries(state.leagueRosters)) {
        const userRoster = rosters.find(roster => (roster.owner_id === (state?.userId?.user_id || '') || ((roster?.co_owners || []).includes((state?.userId?.user_id || '')))))
        if (userRoster)
          playerUserIds[leagueId] = userRoster.roster_id
      }
      return playerUserIds
    },
  },
  actions: {
    isDataFresh(dataKey: keyof iLineupRefreshDates, force: boolean = false) {
      const isFresh = (Date.now() - lineupRefreshTimes[dataKey].refreshTime) < this.refreshDates[dataKey]
      return force ? false : isFresh
    },
    // resetFreshData() {
    //   this.$patch((state) => {
    //     for (const key of Object.keys(state.refreshDates)) {
    //       state.refreshDates[key as keyof iLineupRefreshDates] = 0
    //     }
    //   })
    // },
    toggleBenchStatus() {
      this.$patch((state) => {
        const statusAnyTrue = this.isAnyBenchOpen
        for (const key of Object.keys(state.benchStatus)) {
          state.benchStatus[key] = !statusAnyTrue
        }
      })
    },
    openModal(leagueId: string, tab?: string) {
      const tabIndex = tab ? defintionLineupTab.findIndex(def => def.slot === tab) : -1
      this.$patch((state) => {
        if (tabIndex !== -1)
          state.modalTab = tabIndex
        state.leagueModal = leagueId
        state.showModal = true
      })
    },
    async getState(force: boolean = false) {
      if (this.isDataFresh('state', force))
        return
      const sleeperState = await fetchSleeperState()
      if (!sleeperState)
        throw new Error ('Sleeper State not found')
      this.$patch((state) => {
        state.state = sleeperState
        state.refreshDates.state = Date.now()
      })
    },
    async getUserId() {
      // if (this.userId !== '')
      //   return
      const user = await fetchSleeperUserId(this.username)
      if (!user || !user.user_id)
        throw new Error ('User not found')
      this.$patch((state) => {
        state.userId = user
      })
    },
    async getGames(force: boolean = false) {
      if (!this.state.week || !this.state.season || this.isDataFresh('games', force))
        return
      const games = await fetchSleeperWeekGames(Number.parseInt(this.state.season), this.state.week)
      this.$patch((state) => {
        state.games = games
        state.refreshDates.games = Date.now()
      })
    },
    async getLeagueData(force: boolean = false) {
      this.$patch({ isLoading: true })
      await this.getState(force)
      await this.getUserId()
      await this.getInjuriesData(force)
      await this.getStartRateData(force)
      await this.getGames(force)
      await this.getLeagueInfo(force)
      await this.getLeagueUserData(force)
      await this.getLeagueRosterData(force)
      await this.getMatchUpData(force)
      await this.fetchWeekProjections(force)
      await this.fetchTrendingPlayers(force)
      this.getUserMatches(force)
      this.$patch({ isLoading: false })
    },
    async getLeagueInfo(force: boolean = false) {
      if (this.isDataFresh('leagues', force))
        return
      const userLeagues = await fetchSleeperUserLeagues(this.userId?.user_id || '')
      if (!userLeagues)
        throw new Error ('No Leagues found')
      const leagues = flattenRecordArray(groupByKey(userLeagues, 'league_id'))
      const status = Object.keys(leagues).reduce((acc, leagueId) => ({ ...acc, [leagueId]: true }), {})
      this.$patch((state) => {
        state.leagues = leagues
        state.benchStatus = status
        state.refreshDates.leagues = Date.now()
      })
    },
    async getLeagueUserData(force: boolean = false) {
      if (this.isDataFresh('leagueUsers', force))
        return
      const leagueUsers: Record<string, iSleeperLeagueUser[]> = {}
      await Promise.all(Object.keys(this.leagues).map(async (leagueId) => {
        const users = await fetchSleeperLeagueUsers(leagueId)
        if (users)
          leagueUsers[leagueId] = users
      }))
      this.$patch((state) => {
        state.leagueUsers = leagueUsers
        state.refreshDates.leagueUsers = Date.now()
      })
    },
    async getLeagueRosterData(force: boolean = false) {
      if (this.isDataFresh('leagueRosters', force))
        return
      const leagueRosters: Record<string, iSleeperRosters[]> = {}
      await Promise.all(Object.keys(this.leagues).map(async (leagueId) => {
        const rosters = await fetchSleeperLeagueRosters(leagueId)
        if (rosters)
          leagueRosters[leagueId] = rosters
      }))
      this.$patch((state) => {
        state.leagueRosters = leagueRosters
        state.refreshDates.leagueRosters = Date.now()
      })
    },
    async getInjuriesData(force: boolean = false) {
      if (this.isDataFresh('injuries', force))
        return
      const injuries = await fetchSleeperWeekInjuries(this.state.week || 1, this.state.season ? Number.parseInt(this.state.season) : new Date().getFullYear())
      const injuriesMap = new Map(Object.entries(injuries))
      this.$patch((state) => {
        state.injuries = injuriesMap
        state.refreshDates.injuries = Date.now()
      })
    },
    async getStartRateData(force: boolean = false) {
      if (this.isDataFresh('startData', force))
        return
      const startData = await fetchSleeperWeekStartRate(2, this.state.week || 1, this.state.season ? Number.parseInt(this.state.season) : new Date().getFullYear())
      this.$patch((state) => {
        state.startData = startData
        state.refreshDates.startData = Date.now()
      })
    },
    async getMatchUpData(force: boolean = false) {
      if (this.isDataFresh('leagueMatchups', force))
        return
      const leagueMatchups: Record<string, iSleeperMatchup[]> = {}
      await Promise.all(Object.keys(this.leagues).map(async (leagueId) => {
        const matchups = await fetchSleeperLeagueMatchups(leagueId, this.state.week || 1)
        if (matchups)
          leagueMatchups[leagueId] = matchups
      }))
      this.$patch((state) => {
        state.leagueMatchups = leagueMatchups
        state.refreshDates.leagueMatchups = Date.now()
      })
    },
    getUserMatches(force: boolean = false) {
      if (this.isDataFresh('userMatches', force))
        return
      const rosterIds = this.getPlayerUserIds
      const userMatches: Record<string, iLineupMatches> = {}
      for (const [leagueId, matchups] of Object.entries(this.leagueMatchups)) {
        const leagueRosterId = rosterIds[leagueId]
        const userMatchup = matchups.find(matchup => matchup.roster_id === leagueRosterId)
        if (userMatchup) {
          const opponentMatchup = matchups.find(matchup => matchup.matchup_id === userMatchup.matchup_id && matchup.roster_id !== leagueRosterId)
          if (opponentMatchup) {
            userMatches[leagueId] = {
              team: this.parseSleeperMatchPlayers(leagueId, userMatchup),
              opponent: this.parseSleeperMatchPlayers(leagueId, opponentMatchup),
            }
          }
        }
      }
      this.$patch((state) => {
        state.userMatches = userMatches
        state.refreshDates.userMatches = Date.now()
      })
      this.parseStartersData(userMatches)
      this.parseChangesData(userMatches)
      this.parseByeChangesData(userMatches)
      this.parseInjuriesData(userMatches)
      this.parseFlexStarters(userMatches)
    },
    parseSleeperMatchPlayers(leagueId: string, data: iSleeperMatchup): iLineupMatch {
      const rosters = this.leagueUsers[leagueId]
      const leagueInfo = this.leagues[leagueId]
      const userData = rosters.find(roster => (roster.user_id === (this.userId?.user_id || '')))
      const leagueName = leagueInfo?.name || ''
      const rosterSetup = leagueInfo?.roster_positions || []
      const isBestBall = leagueInfo?.settings?.best_ball || false
      const name = userData?.display_name || ''
      const currentPointsMap = new Map(Object.entries(data.players_points).map(([p, v]) => [p, v || 0]))
      const startPlayers = data.starters || []
      const benchPlayers = data.players || []
      const startRateMap = new Map(Object.entries(this.startData).map(([playerId, { started }]) => [playerId, started / 100]))
      const gameMap = createSleeperGamesMap(this.games)
      const mapPlayer = (p: string, rosterPosition: string): iPlayerLineup => {
        const playerScoring = calculateSleeperScoring(p, this.projections, leagueInfo, this.state.week || 1)
        return {
          id_sleeper: p,
          currentProjection: 0,
          startRate: startRateMap.get(p) || 0,
          gameDateType: parsePlayerGameType(gameMap.get(playerScoring?.id_team || '')),
          ...parseInjuryStatus(this.injuries.get(p)),
          ...playerScoring,
          currentPoints: currentPointsMap.get(p) || 0,
          rosterPosition,
        }
      }
      const starters: Array<iPlayerLineup> = startPlayers.map((p, index) => mapPlayer(p, rosterSetup[index]))
      const bench: Array<iPlayerLineup> = benchPlayers.filter(p => !startPlayers.includes(p)).map(p => mapPlayer(p, 'BN'))
      addProjectionsPerc(starters)
      addProjectionsPerc(bench)
      return { bench, starters, points: data.points, leagueId, isBestBall, leagueName, name }
    },
    async fetchWeekProjections(force: boolean = false) {
      if (this.isDataFresh('projections', force))
        return
      const week = this.state.week
      if (!week)
        return
      const projections = await useBppSimpleFetch<Array<iSleeperProjections>>(
        '/proj/weekly',
        { query: { week } },
        { auth: false, errors: true },
      )
      this.$patch((state) => {
        state.projections = new Map(projections.map(p => [p.id_sleeper, p]))
        state.refreshDates.projections = Date.now()
      })
    },
    async fetchTrendingPlayers(force: boolean = false) {
      if (this.isDataFresh('waivers', force))
        return
      const trendingPlayers = await fetchSleeperTrendingPlayers('add', 24, 50)
      const leaguePlayers: Record<string, Set<string>> = extractLeaguePlayerSet(this.leagueRosters)

      this.$patch((state) => {
        state.leaguePlayers = leaguePlayers
        state.trendingWaivers = parseTrendingWaivers(trendingPlayers, leaguePlayers)
        state.ownershipWaivers = parseOwnershipWaivers(this.startData, leaguePlayers)
        state.refreshDates.waivers = Date.now()
      })
    },
    parseStartersData(userMatches: Record<string, iLineupMatches>) {
      this.$patch((state) => {
        state.starters = parseLineupStarters(userMatches)
      })
    },
    parseInjuriesData(userMatches: Record<string, iLineupMatches>) {
      this.$patch((state) => {
        state.injuryChanges = extractInjuryChanges(userMatches, this.leagues)
      })
    },
    parseChangesData(userMatches: Record<string, iLineupMatches>) {
      this.$patch((state) => {
        state.playerChanges = extractStartRateChanges(userMatches, this.leagues)
      })
    },
    parseFlexStarters(userMatches: Record<string, iLineupMatches>) {
      this.$patch((state) => {
        state.flexChanges = extractFlexChanges(userMatches, this.leagues)
      })
    },
    parseByeChangesData(userMatches: Record<string, iLineupMatches>) {
      this.$patch((state) => {
        state.byeChanges = extractByeChanges(userMatches, this.leagues)
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLineupStore, import.meta.hot))
