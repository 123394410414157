import type { DateTime } from 'luxon'

const rosterPositionEligible: Record<string, string[]> = {
  QB: ['QB'],
  SUPER_FLEX: ['QB', 'RB', 'WR', 'TE'],
  RB: ['RB'],
  FLEX: ['RB', 'WR', 'TE'],
  TE: ['TE'],
  REC_FLEX: ['WR', 'TE'],
  WR: ['WR'],
  K: ['K'],
}

const injuryStates: Record<iSleeperInjuryStatus, string> = {
  Questionable: 'Q',
  IR: 'I',
  Out: 'O',
  PUP: 'I',
  I: 'I',
  Doubtful: 'D',
  DNR: 'O',
  Sus: 'O',
  NA: 'O',
}

export const percRangesProjections: percRangesProjections = {
  currentPoints: {
    QB: [12, 24],
    RB: [8, 22],
    WR: [8, 22],
    TE: [6, 18],
  },
  currentProjection: {
    QB: [10, 22],
    RB: [6, 18],
    WR: [6, 18],
    TE: [5, 16],
  },
  pass_att: {
    QB: [20, 40],
    RB: [0, 0],
    WR: [0, 0],
    TE: [0, 0],
  },
  pass_comp: {
    QB: [15, 25],
    RB: [0, 0],
    WR: [0, 0],
    TE: [0, 0],
  },
  pass_yards: {
    QB: [150, 300],
    RB: [0, 0],
    WR: [0, 0],
    TE: [0, 0],
  },
  pass_td: {
    QB: [1, 2.5],
    RB: [0, 0],
    WR: [0, 0],
    TE: [0, 0],
  },
  pass_int: {
    QB: [0, 1],
    RB: [0, 0],
    WR: [0, 0],
    TE: [0, 0],
  },
  rush_att: {
    QB: [0, 8],
    RB: [5, 20],
    WR: [0, 3],
    TE: [0, 3],
  },
  rush_yards: {
    QB: [0, 50],
    RB: [20, 80],
    WR: [0, 20],
    TE: [0, 20],
  },
  rush_td: {
    QB: [0, 1],
    RB: [0, 1],
    WR: [0, 0.3],
    TE: [0, 0.3],
  },
  rec_rec: {
    QB: [0, 0],
    RB: [0, 5],
    WR: [0, 8],
    TE: [0, 6],
  },
  rec_yards: {
    QB: [0, 0],
    RB: [5, 40],
    WR: [20, 90],
    TE: [10, 70],
  },
  rec_td: {
    QB: [0, 0],
    RB: [0, 0.3],
    WR: [0, 0.5],
    TE: [0, 0.5],
  },
  fum: {
    QB: [0, 0.3],
    RB: [0, 0.2],
    WR: [0, 0.2],
    TE: [0, 0.2],
  },
}

export function calculateSleeperScoring(
  playerId: string,
  projections: Map<string, iSleeperProjections>,
  league: iSleeperLeague,
  currentWeek: number = 0,
) {
  const scoring = league.scoring_settings
  const playerProjection = projections.get(playerId)
  const playerData = usePlayerDataStore().getPlayerData(playerId, 'id_sleeper')
  if (!playerData)
    return
  const byeWeek = scheduleOpponents.get(playerData.id_team || '')?.bye ?? 0
  const isByeWeek = currentWeek === byeWeek
  if (!playerProjection)
    return { ...playerData, byeWeek, isByeWeek }
  let fantasyPoints = 0

  // Passing
  fantasyPoints += (playerProjection.pass_att || 0) * (scoring.pass_att || 0)
  fantasyPoints += (playerProjection.pass_comp || 0) * (scoring.pass_cmp || 0)
  fantasyPoints += (playerProjection.pass_yards || 0) * (scoring.pass_yd || 0)
  fantasyPoints += (playerProjection.pass_td || 0) * (scoring.pass_td || 0)
  fantasyPoints += (playerProjection.pass_int || 0) * (scoring.pass_int || 0)
  // Rushing
  fantasyPoints += (playerProjection.rush_att || 0) * (scoring.rush_att || 0)
  fantasyPoints += (playerProjection.rush_yards || 0) * (scoring.rush_yd || 0)
  fantasyPoints += (playerProjection.rush_td || 0) * (scoring.rush_td || 0)
  // Receiving
  fantasyPoints += (playerProjection.rec_rec || 0) * (scoring.rec || 0)
  fantasyPoints += (playerProjection.rec_yards || 0) * (scoring.rec_yd || 0)
  fantasyPoints += (playerProjection.rec_td || 0) * (scoring.rec_td || 0)
  // Receiving Position
  if (playerData.id_pos === 'QB')
    fantasyPoints += (playerProjection.rec_rec || 0) * (scoring.bonus_rec_qb || 0)
  if (playerData.id_pos === 'RB')
    fantasyPoints += (playerProjection.rec_rec || 0) * (scoring.bonus_rec_rb || 0)
  if (playerData.id_pos === 'WR')
    fantasyPoints += (playerProjection.rec_rec || 0) * (scoring.bonus_rec_wr || 0)
  if (playerData.id_pos === 'TE')
    fantasyPoints += (playerProjection.rec_rec || 0) * (scoring.bonus_rec_te || 0)
  // Fumbles
  fantasyPoints += (playerProjection.fum || 0) * (scoring.fum || 0)
  // Bonuses
  if (scoring.bonus_pass_cmp_25 && (playerProjection.pass_comp || 0) >= 25)
    fantasyPoints += scoring.bonus_pass_cmp_25
  if (scoring.bonus_pass_yd_400 && (playerProjection.pass_yards || 0) >= 300)
    fantasyPoints += scoring.bonus_pass_yd_400
  if (scoring.bonus_pass_yd_300 && (playerProjection.pass_yards || 0) >= 300)
    fantasyPoints += scoring.bonus_pass_yd_300
  if (scoring.bonus_rush_yd_100 && (playerProjection.rush_yards || 0) >= 100)
    fantasyPoints += scoring.bonus_rush_yd_100
  if (scoring.bonus_rec_yd_100 && (playerProjection.rec_yards || 0) >= 100)
    fantasyPoints += scoring.bonus_rec_yd_100

  return {
    ...playerData,
    ...playerProjection,
    byeWeek,
    isByeWeek,
    currentProjection: roundNumber(fantasyPoints, 2),
  }
}

export function parseInjuryStatus(injuryData?: iSleeperInjuriesMetadata) {
  if (!injuryData)
    return {}

  return {
    ...injuryData,
    injury_state: injuryData.injury_status ? injuryStates[injuryData.injury_status] : undefined,
  }
}

export function isPlayerInjured(player: iPlayerLineup) {
  return ['IR', 'Out', 'PUP', 'I', 'Doubtful', 'DNR', 'Sus', 'NA'].includes(player.injury_status || '')
}

export function extractByeChanges(userMatches: Record<string, iLineupMatches>, leagues: Record <string, iSleeperLeague>) {
  const leagueSet = new Set<string>()
  const result: Array<iLineupPlayerChanges> = []
  const changes: Array<{ origin: iPlayerLineup, target: iPlayerLineup, leagueId: string }> = []

  for (const [matchKey, match] of Object.entries(userMatches)) {
    if (match.team.isBestBall)
      continue
    for (const startPlayer of match.team.starters) {
      if (startPlayer.currentPoints > 0)
        continue
      if (startPlayer.isByeWeek) {
        leagueSet.add(matchKey)
        const elegiblePositions = rosterPositionEligible[startPlayer.id_pos || '']
        if (!elegiblePositions)
          continue
        const targetPlayers = match.team.bench.filter(p => elegiblePositions.includes(p.id_pos || '') && !p.isByeWeek && !(p.currentPoints > 0) && !isPlayerInjured(p)).sort(sortByAttribute('startRate', 'desc'))
        const target = targetPlayers.length ? targetPlayers[0] : match.team.bench.filter(p => !p.isByeWeek && !(p.currentPoints > 0) && !isPlayerInjured(p)).sort(sortByAttribute('startRate', 'desc'))?.[0]
        changes.push({ origin: startPlayer, target, leagueId: matchKey })
      }
    }
  }
  for (const league of leagueSet)
    result.push({ leagueId: league, leagueName: leagues[league]?.name, changes: changes.filter(c => c.leagueId === league) })
  return result.sort((a, b) => b.changes.length - a.changes.length)
}

export function extractInjuryChanges(userMatches: Record<string, iLineupMatches>, leagues: Record <string, iSleeperLeague>) {
  const leagueSet = new Set<string>()
  const result: Array<iLineupPlayerChanges> = []
  const changes: Array<{ origin: iPlayerLineup, target: iPlayerLineup, leagueId: string }> = []

  for (const [matchKey, match] of Object.entries(userMatches)) {
    if (match.team.isBestBall)
      continue
    for (const startPlayer of match.team.starters) {
      if (startPlayer.currentPoints > 0)
        continue
      const isInjured = isPlayerInjured(startPlayer)
      if (!isInjured)
        continue
      leagueSet.add(matchKey)
      const elegiblePositions = rosterPositionEligible[startPlayer.id_pos || '']
      if (!elegiblePositions)
        continue
      const targetPlayers = match.team.bench.filter(p => elegiblePositions.includes(p.id_pos || '') && !p.isByeWeek && !(p.currentPoints > 0) && !isPlayerInjured(p)).sort(sortByAttribute('startRate', 'desc'))
      const target = targetPlayers.length ? targetPlayers[0] : match.team.bench.sort(sortByAttribute('startRate', 'desc'))?.[0]
      changes.push({ origin: startPlayer, target, leagueId: matchKey })
    }
  }
  for (const league of leagueSet)
    result.push({ leagueId: league, leagueName: leagues[league]?.name, changes: changes.filter(c => c.leagueId === league) })
  return result.sort((a, b) => b.changes.length - a.changes.length)
}

export function extractFlexChanges(userMatches: Record<string, iLineupMatches>, leagues: Record <string, iSleeperLeague>): Array<iLineupPlayerChanges> {
  const leagueSet = new Set<string>()

  const result: Array<iLineupPlayerChanges> = []
  const changes: Array<{ origin: iPlayerLineup, target: iPlayerLineup, leagueId: string }> = []

  for (const [matchKey, match] of Object.entries(userMatches)) {
    const { isBestBall, starters } = match.team
    if (isBestBall)
      continue
    for (const startPlayer of starters) {
      if (startPlayer.currentPoints > 0 || isPlayerInjured(startPlayer))
        continue
      const remainingPlayers = starters.filter(p => p.id_sleeper !== startPlayer.id_sleeper && !isPlayerInjured(p) && p.currentPoints === 0 && p.isByeWeek === false && p.id_pos === startPlayer.id_pos)
      if (['TNF', 'Sat'].includes(startPlayer.gameDateType) && ['FLEX', 'REC_FLEX', 'SUPER_FLEX'].includes(startPlayer.rosterPosition)) {
        const flexReplacements = remainingPlayers.filter(p => !['FLEX', 'REC_FLEX', 'SUPER_FLEX'].includes(p.rosterPosition) && !(['TNF', 'Sat'].includes(p.gameDateType)))
        if (flexReplacements.length) {
          changes.push({ origin: startPlayer, target: flexReplacements[0], leagueId: matchKey })
          leagueSet.add(matchKey)
        }
      }
      if (['MNF', 'SNF'].includes(startPlayer.gameDateType) && !['FLEX', 'REC_FLEX', 'SUPER_FLEX'].includes(startPlayer.rosterPosition)) {
        const flexReplacements = remainingPlayers.filter(p => ['FLEX', 'REC_FLEX', 'SUPER_FLEX'].includes(p.rosterPosition) && !(['MNF', 'SNF'].includes(p.gameDateType)))
        if (flexReplacements.length) {
          changes.push({ origin: startPlayer, target: flexReplacements[0], leagueId: matchKey })
          leagueSet.add(matchKey)
        }
      }
    }
  }
  for (const league of leagueSet)
    result.push({ leagueId: league, leagueName: leagues[league]?.name, changes: changes.filter(c => c.leagueId === league) })
  return result.sort((a, b) => b.changes.length - a.changes.length)
}

export function extractStartRateChanges(userMatches: Record<string, iLineupMatches>, leagues: Record <string, iSleeperLeague>) {
  const leagueSet = new Set<string>()
  const result: Array<iLineupPlayerChanges> = []
  const changes: Array<{ origin: iPlayerLineup, target: iPlayerLineup, leagueId: string }> = []
  for (const [matchKey, match] of Object.entries(userMatches)) {
    if (match.team.isBestBall)
      continue
    const minValue: Record<string, { player: iPlayerLineup, min: number } | undefined> = {
      QB: extractPositionPlayers(match.team.starters, ['QB', 'SUPER_FLEX']),
      RB: extractPositionPlayers(match.team.starters, ['RB', 'SUPER_FLEX', 'FLEX']),
      TE: extractPositionPlayers(match.team.starters, ['TE', 'REC_FLEX', 'SUPER_FLEX', 'FLEX']),
      WR: extractPositionPlayers(match.team.starters, ['WR', 'REC_FLEX', 'SUPER_FLEX', 'FLEX']),
    }
    for (const benchPlayer of match.team.bench) {
      if (['I', 'O', 'D'].includes(benchPlayer.injury_state || ''))
        continue
      const { player, min } = minValue[benchPlayer.id_pos || ''] || {}

      if (player && !(player.currentPoints > 0) && min && !benchPlayer.isByeWeek && !(benchPlayer.currentPoints > 0) && min < benchPlayer.startRate && !isPlayerInjured(benchPlayer)) {
        if (player.id_pos === 'QB' && benchPlayer.id_pos !== 'QB')
          continue
        leagueSet.add(matchKey)
        changes.push({ origin: player, target: benchPlayer, leagueId: matchKey })
      }
    }
  }
  for (const league of leagueSet)
    result.push({ leagueId: league, leagueName: leagues[league]?.name, changes: changes.filter(c => c.leagueId === league) })
  return result.sort((a, b) => b.changes.length - a.changes.length)
}

export function extractPositionPlayers(players: Array<iPlayerLineup>, arrPositions: Array<string>): { player: iPlayerLineup, min: number } | undefined {
  const avaliablePlayers = players.filter(p => arrPositions.includes(p.rosterPosition)).sort(sortByAttribute('startRate', 'asc'))
  if (avaliablePlayers.length === 0)
    return undefined
  return { player: avaliablePlayers[0], min: avaliablePlayers[0].startRate }
}

export function addProjectionsPerc(dataset: Array<iPlayerLineup>) {
  return dataset.map((player) => {
    const position = player?.id_pos as 'QB' | 'RB' | 'WR' | 'TE'
    if (!position || !['QB', 'RB', 'WR', 'TE'].includes(position))
      return player
    const keys = ['currentPoints', 'currentProjection', 'pass_att', 'pass_comp', 'pass_yards', 'pass_td', 'pass_int', 'rush_att', 'rush_yards', 'rush_td', 'rec_rec', 'rec_yards', 'rec_td', 'fum'] as const
    for (const key of keys) {
      player[`${key}_perc`] = normalizeValue(player[key] || 0, percRangesProjections[key][position][0], percRangesProjections[key][position][1], false)
    }
    player.currentPoints_perc = normalizeValue(player.currentPoints, ((player?.currentProjection || 0) - 4), ((player?.currentProjection || 0) + 4), true)
    player.startRate_perc = normalizeValue(player.startRate, 0, 1, false)
    return player
  })
}

export function updatePlayerStats(playerMap: Map<string, iPlayerLineupStarters>, player: iPlayerLineup, isTeam: boolean, isStarter: boolean, isBB: boolean) {
  if (!playerMap.has(player.id_sleeper)) {
    const baseStats = {
      total: 0,
      starter: 0,
      bench: 0,
    }
    playerMap.set(player.id_sleeper, {
      ...player,
      base: { ...baseStats },
      bb: { ...baseStats },
      baseOpp: { ...baseStats },
      bbOpp: { ...baseStats },
      totalPlayers: {
        base: 0,
        bb: 0,
      },
    })
  }
  const playerStats = playerMap.get(player.id_sleeper)!
  playerStats[isTeam ? 'bb' : 'bbOpp'][isStarter ? 'starter' : 'bench']++
  playerStats[isTeam ? 'bb' : 'bbOpp'].total++
  playerStats.totalPlayers.base++
  if (!isBB) {
    playerStats[isTeam ? 'base' : 'baseOpp'][isStarter ? 'starter' : 'bench']++
    playerStats[isTeam ? 'base' : 'baseOpp'].total++
    playerStats.totalPlayers.bb++
  }
}

export function parseLineupStarters(userMatches: Record<string, iLineupMatches>) {
  const playerMap = new Map<string, iPlayerLineupStarters>()
  for (const game of Object.values(userMatches)) {
    const { isBestBall, starters, bench } = game.team
    const { starters: startersOpp, bench: benchOpp } = game.opponent
    for (const player of starters)
      updatePlayerStats(playerMap, player, true, true, isBestBall)
    for (const player of bench)
      updatePlayerStats(playerMap, player, true, false, isBestBall)
    for (const player of startersOpp)
      updatePlayerStats(playerMap, player, false, true, isBestBall)
    for (const player of benchOpp)
      updatePlayerStats(playerMap, player, false, false, isBestBall)
  }
  const players = Array.from(playerMap.values())
  const maxTotal = Math.max(...players.map(p => p.base.total))
  const maxTotalBb = Math.max(...players.map(p => p.bb.total))
  const maxStarter = Math.max(...players.map(p => p.base.starter))
  const maxStarterBb = Math.max(...players.map(p => p.bb.starter))
  const maxBench = Math.max(...players.map(p => p.base.bench))
  const maxBenchBb = Math.max(...players.map(p => p.bb.bench))
  const starters = players.map((p) => {
    const base = {
      ...p.base,
      total_perc: normalizeValue(p.base.total, 0, maxTotal),
      starter_perc: normalizeValue(p.base.starter, 0, maxStarter),
      bench_perc: normalizeValue(p.base.bench, 0, maxBench),
    }
    const bb = {
      ...p.bb,
      total_perc: normalizeValue(p.bb.total, 0, maxTotalBb),
      starter_perc: normalizeValue(p.bb.starter, 0, maxStarterBb),
      bench_perc: normalizeValue(p.bb.bench, 0, maxBenchBb),
    }
    return {
      ...p,
      base,
      bb,
    }
  })
  return starters
}

export function createSleeperGamesMap(games: Array<iSleeperGame>) {
  const gameMap: Map<string, iSleeperGame> = new Map()
  for (const game of games) {
    gameMap.set(game.metadata.home_team, game)
    gameMap.set(game.metadata.away_team, game)
  }
  return gameMap
}

export function parsePlayerGameType(game?: iSleeperGame): iGameDateType {
  if (!game || !game.start_time)
    return 'Unk'
  const startTime: DateTime<boolean> = genLuxonDateObject(game.start_time)
  switch (startTime.weekday) {
    case 4:
    case 5:
      return 'TNF'
    case 6:
      return 'Sat'
    case 7:
      return startTime.hour < 21 ? 'Sun Early' : 'Sun Late'
    case 1:
      return 'SNF'
    case 2:
    case 3:
      return 'MNF'
    default:
      return 'Unk'
  }
}

export function extractLeaguePlayerSet(leagueRosters: Record<string, Array<iSleeperRosters>>): Record<string, Set<string>> {
  const leaguePlayers: Record<string, Set<string>> = {}
  for (const [leagueId, rosters] of Object.entries(leagueRosters))
    leaguePlayers[leagueId] = new Set<string>(rosters.flatMap(({ starters, players, reserve, taxi }) => [...starters, ...players || [], ...reserve || [], ...taxi || []]))
  return leaguePlayers
}

export function parseTrendingWaivers(trendingPlayers: Array<iSleeperTrendingPlayer>, leaguePlayersRecord: Record<string, Set<string>>) {
  const PlayerDataStore = usePlayerDataStore()
  const result: Array<iPlayerWaivers> = []
  const leaguePlayers = Object.entries(leaguePlayersRecord)
  for (const { player_id, count } of trendingPlayers) {
    const player = PlayerDataStore.getPlayerData(player_id, 'id_sleeper')
    if (!player)
      continue
    const leagueAvailability: Record<string, boolean> = {}
    let isWaiverAvailable = false
    for (const [leagueId, rosters] of leaguePlayers) {
      const isAvailable = !rosters.has(player_id)
      leagueAvailability[leagueId] = isAvailable
      if (isAvailable)
        isWaiverAvailable = true
    }

    result.push({ ...player, addCount: count, leagueAvailability, isWaiverAvailable })
  }
  return result
}

export function parseOwnershipWaivers(startData: iSleeperStartRate, leaguePlayersRecord: Record<string, Set<string>>) {
  const ownershipPlayers = Object.entries(startData).map(([id_sleeper, { owned }]) => ({ id_sleeper, ownership: owned / 100 })).sort(sortByAttribute('ownership', 'desc'))
  const PlayerDataStore = usePlayerDataStore()
  const result: Array<iPlayerWaivers> = []
  const leaguePlayers = Object.entries(leaguePlayersRecord)
  for (const { id_sleeper, ownership } of ownershipPlayers) {
    const leagueAvailability: Record<string, boolean> = {}
    let isWaiverAvailable = false
    for (const [leagueId, rosters] of leaguePlayers) {
      const isAvailable = !rosters.has(id_sleeper)
      leagueAvailability[leagueId] = isAvailable
      if (isAvailable)
        isWaiverAvailable = true
    }
    if (!isWaiverAvailable)
      continue
    const player = PlayerDataStore.getPlayerData(id_sleeper, 'id_sleeper')
    if (!player || !['QB', 'RB', 'WR', 'TE'].includes(player.id_pos || ''))
      continue
    result.push({ ...player, ownership, leagueAvailability, isWaiverAvailable })
    if (result.length >= 200)
      break
  }
  return result
}
